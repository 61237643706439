import React from "react";
// import Styles from "./SelectedRouterInfo.module.scss";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import CrossIcon from "@vahak/core/dist/icons/cross.svg";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Typography from "@vahak/core-ui/dist/components/Typography";
import PostingChip from "../../../../posting-form/common/posting-chip/PostingChip";
import { SelectOption } from "@vahak/core-ui/dist/components/Select";
import { PriceDestination } from "../../../helpers/constants";

/*
 * id=> place_id
 * value=> { price , location }
 * label => [ city | price]
 */

type RouteOption = SelectOption<PriceDestination, string, string>;

interface SelectedRouterInfoProps {
    titleText?: string;
    onAdd?: (cities: RouteOption) => void;
    onRemove?: (cities: RouteOption) => void;
    activeCities?: RouteOption[];
    showAnyDestinationOption?: boolean;
}

const ANY_DESTINATION_ID = "ANY_DESTINATION";

export const AnyDestinationOption: RouteOption = {
    label: "Any destination within 250 km",
    id: ANY_DESTINATION_ID,
    value: {
        price: 0,
        place: {
            place_id: ANY_DESTINATION_ID
        }
    }
};
const SelectedRouterInfo = ({
    titleText = "Where would you like to go?",
    onRemove,
    onAdd,
    activeCities: _activeCities = [],
    showAnyDestinationOption
}: SelectedRouterInfoProps) => {
    const activeCities = _activeCities?.filter((v) => v.id != AnyDestinationOption?.id && !!v?.label);
    const renderedOption = showAnyDestinationOption ? [AnyDestinationOption, ...activeCities] : activeCities;

    return (
        <VerticalFlex gap={14}>
            <Typography weight="medium" color={COLORS.GREY_600} mSize="xs" size="sm">
                {titleText}
            </Typography>
            <Flex flexWrap="wrap" gap={8}>
                {renderedOption?.map((item) => {
                    const isActive = _activeCities?.findIndex((activeCity) => activeCity?.id === item?.id) >= 0;
                    return (
                        <PostingChip
                            size="xs"
                            onClick={() => onAdd?.(item)}
                            {...(isActive && {
                                active: true,
                                color: "blue",
                                outlined: true,
                                rightNode: (
                                    <IconWrapper width={18} height={18} svgFillColor={COLORS.GREY_700}>
                                        <CrossIcon />
                                    </IconWrapper>
                                ),
                                onClick: () => onRemove?.(item)
                            })}
                        >
                            <Typography size="s">{item?.label}</Typography>
                        </PostingChip>
                    );
                })}
            </Flex>
        </VerticalFlex>
    );
};

export default SelectedRouterInfo;
